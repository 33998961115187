import values from 'lodash/fp/values'
import PropTypes from 'prop-types'


const constants = {
    IDLE: 'idle',
    FAILED: 'failed',
    LOADING: 'loading',
    SUCCEEDED: 'succeeded'
}

export const types = PropTypes.oneOf(values(constants))

export default constants
