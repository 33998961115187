import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit'
import { client } from '../../api/client'
import config from '../../config';

const apiUrl = ''
const apiEndpoints = config.api;

const noticeAdapter = createEntityAdapter({
  selectId: a => a.Id,
  sortComparer: (a, b) => b.UpdatedDate.localeCompare(a.UpdatedDate),
})

const initialState = noticeAdapter.getInitialState({
  status: 'idle',
  error: null,
})

export const fetchNotices = createAsyncThunk('home/fetchNotices', async () => {
  const endpoint = apiUrl.concat(apiEndpoints.notice.get);
  const response = await client.get(endpoint)
  return response
}
)

const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
  },
  extraReducers: {
    [fetchNotices.pending]: (state, action) => {
      state.status = 'loading'
    },
    [fetchNotices.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      // Add any fetched posts to the array      
      noticeAdapter.upsertMany(state, action.payload)
    },
    [fetchNotices.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    }
  },
})

export default homeSlice.reducer
export const {
  selectAll: selectAllNotices
} = noticeAdapter.getSelectors((state) => state.home)

