import {createSlice,createAsyncThunk, createEntityAdapter  } from '@reduxjs/toolkit'
import { client } from '../../api/client'
import config from '../../config';
  
  const apiUrl = ''
  const apiEndpoints = config.api;
  
  const indicesAdapter = createEntityAdapter({
    selectId: a => a.Id,
    sortComparer: (a, b) => b.CreatedDate.localeCompare(a.CreatedDate),
  })
  
  export const indicesSelectors = indicesAdapter.getSelectors((state)=>state.indices);

  const initialState = indicesAdapter.getInitialState({
    status: 'idle',
    error: null,
  })
  
  export const fetchIndices = createAsyncThunk('indices/fetchIndices', async (find) => {
      let endpoint="";
      let encodedSearchParam="";
      if(!find || find==='' || find===null)
         endpoint = apiUrl.concat(apiEndpoints.index.getAll);
      else
         encodedSearchParam = encodeURIComponent(find)
         endpoint = apiUrl.concat(apiEndpoints.index.getIndexes).concat('/').concat(encodedSearchParam);

    const response = await client.get(endpoint);
    
    return response
  })
  
  
  const indicesSlice = createSlice({
    name: 'indices',
    initialState,
    reducers: {
    },
    extraReducers: {
        [fetchIndices.pending]: (state, action) => {
          state.status = 'loading'
        },
        [fetchIndices.fulfilled]: (state, action) => {
          state.status = 'succeeded'
          // Add any fetched posts to the array      
          indicesAdapter.setAll(state, action.payload)
        },
        [fetchIndices.rejected]: (state, action) => {
          state.status = 'failed'
          state.error = action.payload
        }
    },
  })
  
  export default indicesSlice.reducer
  
  export const {  selectAll: selectAllIndices } = indicesAdapter.getSelectors((state) => state.indices)
  
  