import React, { Component } from 'react';
import './Header.css';
import Container from 'react-bootstrap/Container'

class Header extends Component {
  render() {
    // return <div className="div-left" style={{ backgroundImage: `url(${bannerImage})`, backgroundRepeat: 'no-repeat', 'backgroundSize': 'cover'}}>
    //   <Container style = {{ 'font-size': '26px'}}>
    //   <h1>RBC Global Index Offering</h1>
    //   </Container>
    // </div>
    return (<div className="div-title" style={{  'backgroundSize': 'cover'}}>
    <Container>
    <p>RBC Global Index Offering</p>
    </Container>
    </div>
     )
        
  }
}

export default Header;