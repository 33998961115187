import moment from 'moment';


export const FORMATTED_DISPLAY_DATE = 'MM/DD/YYYY';
export const FORMATTED_DISPLAY_DATE_MMM = 'DD-MMM-YYYY';
export const FORMATTED_DATE = 'YYYY-MM-DD';
export const FORMATTED_DATE_TIME = 'YYYY-MM-DD HH:mm:ss';
export const FORMATTED_YEAR_MONTH = 'MMM-YYYY';
export const FORMATTED_MONTH_DATE_YEAR = 'MMMM DD, yyyy';

export const MIN_DATE = new Date('1900-01-01');

const isValidDate = date => {
    return moment(date).isValid() && moment(date).isSameOrAfter(MIN_DATE);
}

export const getCurrentDate = (format = FORMATTED_DATE) => {
    return moment().format(format);
}

export const formatDate = (value, format = FORMATTED_DISPLAY_DATE) => {
    return value && isValidDate(value) ? moment(value).format(format) : null;
}

export const formatDateinMMM = (value, format = FORMATTED_DISPLAY_DATE_MMM) => {
    return value && isValidDate(value) ? moment(value).format(format) : null;
}

export const formatYearMonth = (value, format = FORMATTED_YEAR_MONTH) => {
    return value && isValidDate(value) ? moment(value).format(format) : null;
}

export const formatMonthDateYear = (value, format = FORMATTED_MONTH_DATE_YEAR) => {
    return value && isValidDate(value) ? moment(value).format(format) : null;
}