export const appendScript = (scriptToAppend) => {
    const script = document.createElement("script");
    script.src = scriptToAppend;
    script.async = true;
    document.body.appendChild(script);
}

export const appendLink = (linkToAppend) => {
    const link = document.createElement("link");
    link.href = linkToAppend;
    link.rel="stylesheet"
    document.body.appendChild(link);
}