import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit'
import { client } from '../../api/client'
import config from '../../config';
import fileDownload from 'js-file-download'

const apiUrl = ''
const apiEndpoints = config.api;

const indexAdapter = createEntityAdapter({
  selectId: a => a.Id,
  sortComparer: (a, b) => b.CreatedDate.localeCompare(a.CreatedDate),
})

const initialState = indexAdapter.getInitialState({
  status: 'idle',
  error: null,
  documents: null
})

export const fetchIndices = createAsyncThunk('index/fetchIndices', async () => {
  const endpoint = apiUrl.concat(apiEndpoints.index.getAll);
  const response = await client.get(endpoint)
  return response
})

export const fetchIndex = createAsyncThunk('index/fetchIndex', async (id) => {
  const endpoint = apiUrl.concat(apiEndpoints.index.get).concat('/').concat(id);
  const response = await client.get(endpoint)
  return response
})

export const fetchDocuments = createAsyncThunk('index/fetchDocuments', async (id) => {
  const endpoint = apiUrl.concat(apiEndpoints.index.getDocumentIds).concat('/').concat(id);
  const response = await client.get(endpoint)
  return response
})

export const fetchDocument = createAsyncThunk('index/fetchDocument', async (id) => {

  const endpoint = apiUrl.concat(apiEndpoints.index.getDocument).concat('/').concat(id);
  let filename = ''
  const data = await client.get(endpoint).then(response => {    
    filename = response.headers.get('filename')
    return response.blob()
  }).then(response => {
    fileDownload(response, filename);
  })
  if (data) {
    console.log("fetch" + id + "succeed")
  }
})

export const fetchIndexWithPeriod = createAsyncThunk('index/fetchIndexWithPeriod', async (values) => {
  const searchParams = new URLSearchParams()
  searchParams.append('id', values.id)
  if (values.start) searchParams.append('startDate', values.start)
  if (values.end) searchParams.append('endDate', values.end)
  const endpoint = apiUrl.concat(apiEndpoints.index.get).concat('?').concat(searchParams.toString());
  const response = await client.get(endpoint)
  return response
})

export const fetchConstituent = createAsyncThunk('index/fetchConstituent', async (id) => {
console.log(apiUrl.concat(apiEndpoints.index.getConstituent).concat('/').concat(id));
  const endpoint = apiUrl.concat(apiEndpoints.index.getConstituent).concat('/').concat(id);
  let filename = ''
  const data = await client.get(endpoint).then(response => {    
    filename = response.headers.get('filename')
    return response.blob()
  }).then(response => {
    fileDownload(response, filename);
  })
  if (data) {
    console.log("fetch" + id + "succeed")
  }
})

const indexSlice = createSlice({
  name: 'indexList',
  initialState,
  reducers: {
  },
  extraReducers: {
    [fetchIndices.pending]: (state, action) => {
      state.status = 'loading'
    },
    [fetchIndices.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      // Add any fetched indices to the array
      indexAdapter.upsertMany(state, action.payload)
    },
    [fetchIndices.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    },
    [fetchIndex.pending]: (state, action) => {
      state.status = 'loading'
    },
    [fetchIndex.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      state.index = action.payload
      state.indexHistorical = action.payload.historicalValues
    },
    [fetchIndex.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    },
    [fetchDocuments.pending]: (state, action) => {
      state.status = 'loading'
    },
    [fetchDocuments.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      state.documents = action.payload
    },
    [fetchDocuments.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    },
    [fetchIndexWithPeriod.pending]: (state, action) => {
      state.status = 'loading'
    },
    [fetchIndexWithPeriod.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      state.index = action.payload
    },
    [fetchIndexWithPeriod.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    }
  }
})

export default indexSlice.reducer

export const {
  selectAll: selectAllIndices,
} = indexAdapter.getSelectors((state) => state.indexList)

