import React from 'react';
import Container from 'react-bootstrap/Container'
import { withStyles } from '@TB80/aura-components'
import './Search.css';
import { useHistory } from 'react-router-dom';
import Cookies from 'universal-cookie';
import searchIcon from '../../../assets/images/search-icon.svg'

const styles = {
    'input': {
        '&::placeholder': {
            opacity: '1',
            'font-size': '15px'
        }
    }
};

const Search = (props) => {
    const cookies = new Cookies();
    const history = useHistory();
    const handleChange = (e) => {
        let convert = e.target.value
        if (e.target.value.indexOf('%') > -1) { // if search contains % convert to %25
            convert = e.target.value.replace(/[%]/g, '%25')
        }
        if(e.target.value.length===0) 
        {
            if (props.index) {
                cookies.set('find', convert, { path: '/' });
                history.push('/indices/');
            }
            else {
                cookies.remove('find', { path: '/' });
                props.find(convert);
            }
        }
    }
    const handleKeyUp = (e) => {
        if (e.key === 'Enter' ||
            e.key === 'Backspace' ||
            e.key === 'Delete') {
            let convert = e.target.value
            if (e.target.value.indexOf('%') > -1) { // handle the case search contains %
                convert = e.target.value.replace(/[%]/g, '%25')
            }
            if (props.index) {
                cookies.set('find', convert, { path: '/' });
                history.push('/indices/');
            }
            else {
                cookies.remove('find', { path: '/' });
                props.find(convert);
            }
        }
    }

    return (<div className="ulSearch">
        <Container style={{ paddingLeft: '10px', display: 'flex' }}>
            <img src={searchIcon} alt='search icon' className='searchImage' />
            <input className="search" id="globalIndexSearch" 

                placeholder="Search by index name / ticker"
                onKeyUp={handleKeyUp}
                type="search"
                onInput={handleChange}
            />

        </Container>
    </div>);
}

export default withStyles(styles)(Search);

// InputProps={{
//     classes: {
//         input: props.classes['input']
//     },
//     disableUnderline: true
// }}