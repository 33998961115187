import React, { useState, useEffect } from 'react'
import './Header.css'
import rbcLogo from '../../assets/images/rbccm_logo.svg'
import { HOME, INDICES } from '../../constants/routes'
import { NavLink } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import Cookies from 'universal-cookie';
import { IconContext } from 'react-icons';
import { SidebarData } from './SidebarData';
import './Navbar.css';
import { Link } from 'react-router-dom';
import {appendLink} from '../appendScript'
const Header = () => {
    const cookies = new Cookies();
    const { pathname } = useLocation()
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [sidebar, setSidebar] = useState(false);

    const showSidebar = () => setSidebar(!sidebar);

    useEffect(() => {
        const changeWidth = () => {
            setScreenWidth(window.innerWidth);
            if (screenWidth > 500) {
                setSidebar(false);
            }
        }
        window.addEventListener('resize', changeWidth);
        
        return () => {
            window.removeEventListener('resize', changeWidth)
        }
    }, [])

    return (
        <div className='container' >
            <header className='d-flex flex-wrap  navbarHeader' style={{'padding-top':'0px; !Important'}}>
            {appendLink('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0')}

                <Link to='#' className={'menu-bars bar'} onClick={showSidebar}>
                    {/* <FaIcons.FaBars onClick={showSidebar} /> */}
                    <span class="material-symbols-outlined spanBarContainer">menu</span>
                </Link>
                <div className='navbar'>
                </div>
                {(!sidebar) && (screenWidth <= 500) && (<a href='/' rel='noreferrer' className=' mb-3 mb-md-0 me-md-auto text-dark text-decoration-none' style={{ float: 'left' }}>
                    <img src={rbcLogo} style={{'padding-top' : '8px;'}} className='rbcImage' alt='RBC Capital Markets' />
                </a>)}
                {(sidebar) && (
                    <IconContext.Provider value={{ color: '#000' }}>

                        {/* <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}> */}
                        <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
                            <ul className='nav-menu-items' onClick={showSidebar} style={{ 'padding': 'inherit' }}>
                                <li className='navbar-toggle' style={{ 'padding': 'inherit' }}>
                                    <div style={{'padding-top' : '8px;'}}>
                                        <a href='/' rel='noreferrer' className=' mb-3 mb-md-0 me-md-auto text-dark text-decoration-none' style={{ float: 'left' }}>
                                            <img src={rbcLogo} className='rbcImage' alt='RBC Capital Markets' style={{ 'height': '43px', 'paddingLeft': '15px' }} />
                                        </a>
                                    </div>
                                    <div>
                                        <Link to='#' className='menu-bars bar'>
                                                    {/* <FontAwesomeIcon icon = {faTimes}  id="closeNavbarIcon"></FontAwesomeIcon> */}
                                                {/* <FaIcons.FaTimes id="closeNavbarIcon" /> */}   
                                                <span class="material-symbols-outlined spanBarContainer" >close</span>                             
                                        </Link>
                                    </div>
                                </li>

                                {SidebarData.map((item, index) => {
                                    return (
                                        <li key={index} className={item.cName} style={{ 'border-bottom': '1px solid #ddd' }}>
                                            <Link to={item.path} style={{ 'paddingLeft': '16px' }} className="dropdownMenu">
                                                <span className="downIcon" style={{ 'font-family': 'Roboto Light,Arial,Verdana,sans-serif','font-size': '15px' }}>{item.title}</span>
                                                {item.icon}
                                            </Link>
                                        </li>
                                    );
                                })}
                            </ul>
                        </nav>
                    </IconContext.Provider>
                )}

                {(screenWidth > 500) && (
                    <div style={{ 'display': 'flex', 'flex': 'auto' }}>
                        <a href='/' rel='noreferrer' className=' mb-3 mb-md-0 me-md-auto text-dark text-decoration-none' style={{ float: 'left' }}>
                            <img src={rbcLogo} className='rbcImage' alt='RBC Capital Markets' />
                        </a>
                        <ul className='nav nav-pills navHeader'>
                            <li className='nav-item mg-top mg-r itemHeader'>
                                <NavLink exact className='nav-link px-2 link-dark' activeClassName="bold-underline" aria-current='page' to={HOME}>Home</NavLink>
                            </li>
                            <li className='nav-item mg-top mg-r itemHeader'>
                                <NavLink
                                    onClick={
                                        () => {
                                            cookies.set('find', '*', { path: '/' });
                                        }
                                    }
                                    exact className='nav-link px-2 link-dark' activeClassName="bold-underline" aria-current='page' to={INDICES} isActive={() => pathname.includes("/index") || pathname.includes(INDICES)}>Current Indices</NavLink>
                            </li>
                        </ul>
                    </div>
                )}
            </header>
        </div >
    )
}


export default React.memo(Header)