import { baseApiPath } from './config.json'
 
const config = {
    api: {
        health: baseApiPath + '/health',
        index: {
            getAll: baseApiPath + '/publishedindexes',
            getIndexes: baseApiPath + '/publishedindexes',
            get: baseApiPath + '/indexDetail',
            getLookup: baseApiPath + '/lookups',
            getDocument: baseApiPath + '/document/file',
            getDocumentIds: baseApiPath + '/indexByDocUrl',
            getConstituent: baseApiPath + '/constituent',
            indexLevel: baseApiPath + '/request/indexLevel'
        },
        notice: {
            get: baseApiPath + '/activenotifications'
        }
    }
}

export default config
