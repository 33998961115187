/* eslint-disable */

export default theme => {

    return {

        root: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%'
        },

        label: {
            paddingLeft: theme.spacing(1)
        }
    }
}
