import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Container from 'react-bootstrap/Container'
import { List, ListItem, ListItemIcon, Paper, Typography } from '@TB80/aura-components'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import { fetchHealth, selectAllHealth } from './healthSlice'
import './health.css'


const HealthCheck = () => {

    const dispatch = useDispatch()

    const healthStatus = useSelector((state) => state.health.status)
    const error = useSelector((state) => state.health.error)
    const healthCheckResults = useSelector(selectAllHealth)

    const [ data, setData ] = useState([])

    useEffect(() => {
        if (healthStatus === 'idle') {
          dispatch(fetchHealth())
        }
    }, [healthStatus, dispatch])

     useEffect(() => {
        if (healthCheckResults && healthCheckResults.length > 0) {
            setData(healthCheckResults[0])
        }
    }, [healthCheckResults])

    return (
    <>
        <div className="div-title" style={{ 'backgroundSize': 'cover', 'margin-top': '20px'}}>
            <Container>
                <p>RBC Global Index Offering - Health Check</p>
            </Container>
        </div>
        <Paper className='health-check-container'>
            <List className='health-check-list'>
                {data && Object.entries(data).map(value => (
                    <ListItem key={value[0]}>
                        <ListItemIcon>
                            { value[1]
                                ? <CheckCircleOutlineIcon style={{ color: 'green'}} />
                                : <ErrorOutlineIcon style={{ color: 'red'}} />
                            }
                        </ListItemIcon>
                        <Typography variant="h2">{value[0]} Status</Typography>
                    </ListItem>
                ))}
            </List>
        </Paper>
    </>)

}

export default HealthCheck;
