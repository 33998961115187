import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import Header from '../../components/indices/header/Header';
import Search from '../../components/indices/search/Search';
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import PropTypes from 'prop-types';
import './Index.css'
import { fetchIndices, fetchIndex, fetchDocuments, fetchDocument, fetchConstituent } from './indexSlice';
import Container from 'react-bootstrap/Container'
import { Link } from 'react-router-dom';
import { HOME, INDICES } from '../../constants/routes'
import { formatMonthDateYear } from '../../constants/constants'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Table from 'react-bootstrap/Table'
import { format, parseJSON } from 'date-fns'
import fetchStatus from '../../constants/fetch-status'
import { email } from '../../config/config.json'
import loadable from 'react-loadable'
import Loader from '../../components/loader';
const LoadablePerformance = loadable({
  loader: () => import('../../components/index/performance/PerformanceComp'),
  loading: () => <div>is loading ...</div>
})
const Index = () => {

  const dispatch = useDispatch()
  const indexStatus = useSelector((state) => state.indexList.status)
  const index = useSelector((state) => state.indexList.index)
  const documents = useSelector((state) => state.indexList.documents)

  const [ histPerformanceData, setHistPerformanceData ] = useState(null)
  const [ indexDates, setIndexDates ] = useState(null)

  useEffect(() => {
    if (index && index.HistoricalValues) {
      setHistPerformanceData(index.HistoricalValues)
    }
  }, [ index ])

  useEffect(() => {
    if (index && index.IndexDates) {
      setIndexDates(index.IndexDates)
    }
  }, [ index ])

  const { id, doc } = useParams()
  const EMAIL = 'mailto:' + email

  let isPublished = true
  if (index) {
    isPublished = index.StatusId === 16
  }

  const [ indexName, setIndexName ] = useState('')

  useEffect(() => {
    if (index && index.Name) {
      setIndexName(index.Name)
    }
  }, [index])

  useEffect(() => {
    dispatch(fetchIndex(id))
    if (doc && indexStatus === 'idle') {
      dispatch(fetchDocuments(doc));
    }
  }, [])

  const SearchButtonHandler = (arg) => {
    dispatch(fetchIndices(arg));
  }

  //if there is a doc parameter in the url, get the documents for the index and display them
  const handleDocumentClick = (id) => {
    dispatch(fetchDocument(id));
  }
  const handleConstituentClick = (id) => {
    dispatch(fetchConstituent(id));
  }
  //if there is a doc parameter in the url, get the documents for the index and display them
  let documentComponent
  let docHeader
  if (doc && documents && documents.length > 0) {
    //if (doc ) {
    docHeader = <div><h4>Methodology Documents</h4>
    </div>

    let docbody = documents.map((doc) => (<tr>
      <th scope="row" xs={2}>{format(parseJSON(doc.UpdatedDate), 'yyyy-MM-dd')}
      </th>
      <th scope="row" xs={6}><a onClick={() => { handleDocumentClick(doc.RandomId) }} href='#/' >{doc.Title}</a>
      </th>
    </tr>))

    documentComponent =
      <Container key={doc.Id}>
        <Table striped hover>
          <thead>
            <tr>
              <th>Publish Date</th>
              <th>Document</th>
            </tr>
          </thead>
          <tbody>
            {docbody}
          </tbody>
        </Table>
      </Container>

  }
  else {
    documentComponent = <p style={{ 'color': 'red' }} >Please contact our group mailbox to get access to the documents: QIS group <a href={EMAIL}>mailbox</a></p>
  }

  return (
    <div role="main" >
      <Header />
      <Search find={SearchButtonHandler} index />
      <Container>
        <Row>
          <Col>
            <br />
            <div className='nav nav-pills'>
              <div className='nav-item '>
                <div >
                  <Link className='nav-link  link-dark' style={{ display: 'initial', 'padding-left': '0.5rem !important' }} aria-current='page' to={HOME}>Home</Link>
                </div>
              </div>|&nbsp;&nbsp;&nbsp;
              <div className='nav-item '>
                <div >
                  <Link className='nav-link  link-dark' style={{ display: 'initial', 'padding-left': '0.5rem !important' }} aria-current='page' to={INDICES}>Current Indices</Link>
                </div>
              </div> { isPublished ? <>|&nbsp;&nbsp;&nbsp;
              
              <div className='nav-item '>
                <div className='roadmap nav-link' style={{ fontWeight: '500', display: 'initial', 'padding-left': '0.5rem !important' }}>{indexName}
                {/* <h3 className='underline-title title-color'>   {index ? index.Name.slice(0, 90) : null}</h3> */}
                </div>
              </div> </>: null}
            </div> 
            <br />
            {/* make a underline span; added a wrapper span to break the words if overflows  */}
            {index && index.Name
                ? <> 
                <h3 className='underline-title title-color'> 
                  <span className='title-wrapper'> 
                    <span className='underline-span'>{indexName ? indexName.slice(0, 5) : null}</span>{indexName ? indexName.slice(5) : null}
                  </span> 
                </h3>
                
                <Tabs defaultActiveKey="overview" className="mb-4">
                  <Tab eventKey="overview" title="Overview" justify transition={false}>
                    <div>
                      <Row>
                        <div className="col-lg-8 col-med-8 col-sm-8">
                          {index ? index.IndexDescription : null}
                        </div>
                        <div className="col-lg-4 col-med-4 col-sm-4">
                          <div className='index-info'>
                            <Row>
                              <Col><b>Ticker:</b>
                                <br />  {index ? index.Ticker : null}
                              </Col>
                              <Col><b>Level</b>:<br /> {index && index.CalculatedValues && index.CalculatedValues[0] ? index.CalculatedValues[0].Value : null}
                              </Col>
                              <Col><b>Currency:</b> <br /> {index && index.Currency ? index.Currency.Name : null}
                              </Col>
                            </Row>
                            <br />
                            <Row>
                              <div className="col-lg-4 col-med-4 col-sm-4"><b>Asset Class:</b><br />{index && index.AssetClass ? index.AssetClass.Name : null}
                              </div>
                              <div className="col-lg-8 col-med-8 col-sm-8"><b>Inception Date:</b> <br />{index && index.IndexInceptionDate ? formatMonthDateYear(parseJSON(index.IndexInceptionDate)) : null}
                              </div>
                            </Row>
                          </div>
                        </div>
                      </Row>
                    </div>
                  </Tab>
                  <Tab eventKey="performance" title="Performance" justify transition={false}>
                    <LoadablePerformance
                      histPerformanceData={histPerformanceData}
                      indexId={id}
                      index={index} 
                      indexDates={indexDates}
                      indLevels={index ? index.IndexLevels : null}>
                    </LoadablePerformance>
                  </Tab>
                  <Tab eventKey="constituents" title="Constituents" justify transition={false}>
                    <p style={{ 'color': 'red' }}>Please <a onClick={() => { handleConstituentClick(id) }} href='#/'>download</a> the file for constituents details.</p>
                  </Tab>
                  <Tab eventKey="documents" title="Documents" justify transition={false}>
                    {docHeader}
                    {documentComponent}
                  </Tab>
                </Tabs> 
              </> 
              : indexStatus === fetchStatus.SUCCEEDED ? <p className='underline-title title-color'>Index Not Found</p> : <Loader size={Loader.Sizes.LARGE} label='Loading...' />
            }
            <br />
          </Col>
        </Row>
      </Container>
    </div>)

}

Index.propTypes = {
  classes: PropTypes.shape({
    grid: PropTypes.string.isRequired,
    root: PropTypes.string.isRequired,
  })
}

export default Index
