/*eslint-disable */
import React from 'react'
import Parser from 'html-react-parser';
import Container from 'react-bootstrap/Container'
import bannerImage from '../../assets/images/home-header.png'
import './legal.css'


const theBody =  
`<div>
<p>Below you&rsquo;ll find information on what cookies are and how we use them to help provide you with the best possible online experience.&nbsp;</p>
<p>Our full Privacy Notice is available on our&nbsp;<a href="https://www.rbc.com/privacysecurity/ca/index.html" target="_blank" rel="noopener">Privacy &amp; Security Page</a>.</p>
<hr />
<p class="contentParagraph">For more on how to control the cookies in your browser, follow the link for your browser below:</p>
<ul>
<li><a href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies" target="_blank" rel="noopener" aria-label="Learn how to control the cookies in Internet Explorer">Internet Explorer</a></li>
<li><a href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?redirectlocale=en-US&amp;redirectslug=Cookies" target="_blank" rel="noopener" aria-label="Learn how to control the cookies in Firefox">Firefox</a></li>
<li><a href="https://support.google.com/chrome/answer/95647?hl=en" target="_blank" rel="noopener" aria-label="&quot;Learn" how="" to="" control="" the="" cookies="" in="" chrome="">Chrome</a></li>
<li><a href="https://support.apple.com/en-us/HT201265" target="_blank" rel="noopener" aria-label="Learn how to control the cookies in Safari">Safari</a></li>
</ul>
<hr />
<h3>What is a cookie?</h3>
<p>A cookie is a file containing a unique identification number that a website sends to your device&rsquo;s web browser. When you visit a website, a cookie may be used to track the activities of your browser as well as provide you with a consistent and efficient experience.</p>
<h3>There are two types of cookies:</h3>
<ul>
<li>Non-persistent (sometimes called &lsquo;session&rsquo;)</li>
<li>Persistent</li>
</ul>
<h3><br />Why does RBC use cookies?</h3>
<p>RBC uses cookies on our websites and advertisements to give you the best possible online user experience and to improve our online services.</p>
<h3>How does RBC use my information?</h3>
<p>You can of course visit our website without revealing any information about yourself. However, if you do give us any personal information about yourself or others, we promise to treat it securely, fairly and lawfully. We collect information on you to deliver services you request, display targeted and relevant advertising, provide more relevant content to you and to learn how we can better meet your needs.</p>
<h3>Does RBC share my information?</h3>
<p>RBC may share information with employees, agents and service providers, who are required to maintain the confidentiality of the information. We may also share information with government agencies, public bodies, regulators, or other entities where permitted or required by law. See our Privacy Notice for more information.</p>
<h3>Use of cookies when browsing RBC websites</h3>
<p>RBC relies on cookies to understand and improve your website experience, to customise advertising to your interests, and to track your usage of our website. Your choice not to accept these cookies will not prohibit your use of our website.</p>
<p>Cookies identify when you visit specific RBC pages and may be shared with third parties to customise advertising on RBC or other websites.</p>
<p>Your choice not to accept these cookies will not prohibit your use of the RBC or third-party site.</p>
<p>RBC does not sell your information to third parties, nor do we track your interaction with a third-party website.</p>
<h3>Can I disable cookies?</h3>
<p>To find out more about cookies or to disable use of cookies then visit <a href="http://www.allaboutcookies.org" target="_blank" rel="noopener" aria-label="&quot;Link" to="" external="" website="" www="" allaboutcookies="" org="">www.allaboutcookies.org</a>&nbsp;or refer to the documentation on your web browser or device regarding how to allow or block cookies.</p>
<p>Blocking cookies will not eliminate all RBC advertising, just advertising that was customised for you, noncustomised advertising may continue to be displayed.</p>
<p>RBC does not manage, and is not responsible for, the digital marketing and cookie practices of third parties, for example, third party websites linked to/from our website.</p>
<hr />
<h3>For more information please view:</h3>
<ul>
<li><a href="https://www.rbc.com/privacysecurity/ca/online-privacy.html" target="_blank" rel="noopener">RBC Digital Channel Privacy</a></li>
<li><a href="https://www.rbc.com/privacysecurity/ca/index.html" target="_blank" rel="noopener">RBC Privacy Page</a></li>
</ul>`

const legal = () => {
    return(<section role="main">
    <div className="div-center" style={{ backgroundImage: `url(${bannerImage})`, backgroundRepeat: 'no-repeat', 'backgroundSize': 'cover', 'minHeight': '300px', 'paddingTop': '90px', color: 'white', 'marginTop': '10px' }}>
      <br />
      <h1>Welcome to <b>RBC</b><br />
        <b>Global Index Offering</b></h1>
    </div>
    <br />
    <Container >
        {Parser(theBody)}
    </Container>
    </section>)
}
export default legal