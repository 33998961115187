/*eslint-disable */
import React, { useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import './Home.css'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Grid from '../../components/UI/grid/indexGrid'
import Container from 'react-bootstrap/Container'
import { format } from 'date-fns'
import chatIcon from '../../assets/images/chat-icon.svg'
import bannerImage from '../../assets/images/home-header.png'
import { fetchNotices, selectAllNotices, } from './homeSlice'
import { fetchIndices, selectAllIndices } from '../index/indexSlice';
import { Link } from 'react-router-dom';
import { INDICES } from '../../constants/routes'
import CellRenderer from '../../components/indices/cellRenderer/CellRenderer'
import Cookies from 'universal-cookie';


let Notice = (notice) => {
  return (
    <article>
      <h4 className='date-font-size'>{format(new Date(notice.notice.CreatedDate), 'dd-MMM-yyyy')}</h4>
      <div style={{ 'overflowWrap': 'break-word' }}>{notice.notice.Details}</div>
    </article>
  )
}

const Home = () => {
  const cookies = new Cookies();
  const dispatch = useDispatch()

  const homeStatus = useSelector((state) => state.home.status)
  const error = useSelector((state) => state.home.error)
  const notices = useSelector(selectAllNotices)
  const indices = useSelector(selectAllIndices)

  useEffect(() => {
    if (homeStatus === 'idle') {
      dispatch(fetchNotices())
      dispatch(fetchIndices())
    }
  }, [homeStatus, dispatch])

  let content

  if (homeStatus === 'loading') {
    content = <div className="loader">Loading...</div>
  } else if (homeStatus === 'succeeded') {
    content = notices.sort(function (a, b) {
      return new Date(b.CreatedDate) - new Date(a.CreatedDate);
    }).map((notice) => (
      <div key={notice.Id}>
        <Notice notice={notice} />
        <br></br>
      </div>
    ))
  }
  else if (homeStatus === 'failed') {
    content = <div>{error}</div>
  }

  const onGridReady = useCallback(params => {
    params.api.setGridAriaProperty('busy', 'true')
  }, [])

  return (
    <section role="main">
      <div className="div-center" style={{ backgroundImage: `url(${bannerImage})`, backgroundRepeat: 'no-repeat', 'backgroundSize': 'cover', 'minHeight': '300px', 'paddingTop': '90px', 'backgroundPosition': 'left', color: 'white' }}>
        <br />
        <h1>Welcome to <b>RBC</b><br />
          <b>Global Index Offering</b></h1>
      </div>
      <br />
      <Container >
        <Row>
          <div className="col-lg-7 col-md-7 col-sm-7" style={{ 'padding': 'inherit', 'height': 'inherit'}}>
            <Container id="wordingcnt">
              <Row style={{ 'height': '25%'}}>
                <Col>
                  <h2 className='underline title-color'>Welcome</h2>
                  <p>

                    RBC indices provides information on strategies and indices of RBC Capital Markets. RBC is an innovative and trusted partner to institutional investors, distributors, corporates and private investors and has a global presence with knowledge of derivatives across asset classes. The RBC range of indices and strategies covers a wide range of assets, including equities, interest rates, credit, commodities and foreign exchange which are either structured as cross-asset allocations or single-asset strategies.
                  </p>
                </Col>
              </Row>
              <Row style={{ 'height': '60%'}}>
                <Col>
                  <br />
                  <h3 className='title-color'>Notices</h3>
                  <div tabIndex="0" className='noticesDiv'>
                    {content}
                  </div>
                  {/* <br></br> */}
                </Col>
              </Row>
              <Row style={{ 'height': 'auto'}}>
                <Col sm={12} md={12} lg={12} xs={12}>
                  <Container className='keyContactsContainer'>
                    <Row className='keyContactsRow'>
                      <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 keyContactSection">
                        <div>
                          <img src={chatIcon} className='chatIcon' alt='RBC Capital Markets' />
                        </div>
                      </div>
                      <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9" style={{ color: 'white', 'marginBottom': '30px', 'marginTop': '20px', 'width': 'auto' }}>
                        <h4>Key Contacts</h4>
                        <p className="globalMarketQIS" style={{ 'marginBottom': '0' }}>
                          Global Markets QIS
                        </p>
                        <p>
                          <a className="globalMarketQIS" href="mailto:qis.global@rbccm.com" style={{ color: 'white' }}>qis.global@rbccm.com</a>
                        </p>
                      </div>
                    </Row>
                  </Container>
                </Col>
              </Row>
              <br></br>
            </Container>
          </div>
          <div className="col-lg-5 col-md-5 col-sm-5" style={{ 'padding': 'inherit', 'height': 'inherit', 'paddingleft': '2px', 'paddingright': '2px' }} >
            <Container id="gridcnt">
              <Row style={{ 'display': 'block'}} id="gridRow">
                <Col>
                  <h3 className='title-color' style={{ 'padding-top': '2%'}}>Top 15 Performing Indices</h3>
                  <Grid className="ag-theme-alpine"
                    {...{
                      columnDefs: [
                        {
                          field: 'Name',
                          headerName: 'Index Name',
                          headerTooltip: 'Index Name',
                          width: 350,
                          minWidth: 200,
                          maxWidth: 350,
                          cellRendererFramework: CellRenderer,
                          headerClass: 'headerCol'
                        },
                        {
                          field: 'AssetClass.Name',
                          headerName: 'Asset Class',
                          headerTooltip: 'Asset Class',
                          headerClass: 'headerCol',
                          width: 100,
                          minWidth: 90
                        },
                        {
                          field: 'SharpeRatio',
                          headerName: 'Sharpe Ratio',
                          headerTooltip: 'Sharpe Ratio',
                          headerClass: 'headerCol',
                          width: 110,
                          minWidth: 100,
                          valueFormatter: (params) => {
                            if (params.value) {
                              return params.value.toFixed(2);
                            }
                          },
                          cellStyle: {
                            'display': 'flex',
                            'justify-content': 'left',
                            'align-items': 'center',
                            'text-align': 'left'
                          },
                          sort: 'desc'
                        },
                        {
                          field: 'CalmarRatio',
                          headerName: 'Calmar Ratio',
                          headerTooltip: 'Calmar Ratio',
                          headerClass: 'headerCol',
                          width: 90,
                          minWidth: 80,
                          valueFormatter: (params) => {
                            if (params.value) {
                              return params.value.toFixed(2);
                            }
                          },
                        }
                      ],
                      defaultColDef: {
                        cellStyle: {
                          'display': 'flex',
                          'justify-content': 'left',
                          'align-items': 'center',
                          'text-align': 'left',
                          'word-break': 'normal'
                        },
                        filter: false,
                        flex: 1,
                        sortable: true,
                        wrapText: true,
                        headerComponentParams: {
                          template:
                            '<div class="ag-cell-label-container" role="grid">' +
                            '  <span class="ag-header-icon ag-header-cell-menu-button"></span>' +
                            '  <div ref="eLabel" class="ag-header-cell-label" role="row">' +
                            '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                            '    <span ref="eSortAsc" aria-hidden="true" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                            '    <span ref="eSortDesc" aria-hidden="true"  class="ag-header-icon ag-sort-descending-icon"></span>' +
                            '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                            '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                            '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                            '  </div>' +
                            '</div>',
                        },
                        resizable: true
                      },
                      rowHeight: 90,
                      rowClass: 'rowGrid',
                      getRowClass: params => {
                        if (params.node.rowIndex % 2 === 0) {
                          return 'white-bg';
                        }
                      },
                      headerHeight: 70,
                      domLayout: 'autoHeight',
                      paginationPageSize: 15,
                      rowData: indices ? indices.sort(function (a, b) {
                        return b.SharpeRatio - a.SharpeRatio;
                      }).slice(0, 15) : null,
                      suppressFieldDotNotation: true,
                      suppressContextMenu: true,
                      suppressCellSelection: true
                    }} />
                  <br />
                </Col>
                <Col>

                  <Link onClick={
                    () => {
                      cookies.set('find', '*', { path: '/' });
                    }
                  } className='align-text-center ' id="more" to={INDICES}>View More</Link>
                  <br />
                </Col>
              </Row>
            </Container>
          </div>

        </Row>
      </Container>

    </section>
  )
}


export default Home