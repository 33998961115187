import React from 'react';
import * as AiIcons from 'react-icons/ai';


export const SidebarData = [
  {
    title: 'Home',
    path: '/',
    icon: <AiIcons.AiFillCaretDown  style={{'width':'14px','height':'14px'}}/>,
    cName: 'nav-text'
  },
  {
    title: 'Current Indices',
    path: '/indices',
    icon: <AiIcons.AiFillCaretDown style={{'width':'14px','height':'14px'}}/>,
    cName: 'nav-text'
  }
];