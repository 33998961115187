import { Root } from '@TB80/aura-components';
import React from 'react'
import { createRoot } from 'react-dom/client'
import './index.css'
import App from './App'
import { store } from './app/store'
import { Provider } from 'react-redux'
import * as serviceWorker from './serviceWorker'


const appTheme = {
  palette: 'light'
}

const domNode = document.getElementById('root')
const root = createRoot(domNode)

root.render(
    <Provider store={store}>
      <Root theme={appTheme}>
        <App />
      </Root>
    </Provider>
)



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
