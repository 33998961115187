import React from 'react'
import './Footer.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedin, faYoutube, faSquareXTwitter} from '@fortawesome/free-brands-svg-icons'
import irocLogo from '../../assets/images/CIRO_Regulated_Light.png'


const Footer = () => {
    return (
        <footer className="footer mt-auto py-6" role='contentinfo'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-8'>
                        <ul className='list-inline'>
                            <li>
                                <a target="_blank" rel="noreferrer" href='https://www.rbc.com/privacysecurity'>PRIVACY</a>
                            </li>
                            <li className='footer-menu-divider' aria-hidden='true'>&#8226;</li>
                            <li>
                                <a target="_blank" rel="noreferrer" href='https://www.rbccm.com/en/policies-disclaimers.page'>LEGAL & DISCLAIMERS</a>
                            </li>
                            <li className='footer-menu-divider' aria-hidden='true'>&#x2022;</li>
                            <li>
                                <a target="_blank" rel="noreferrer" href='https://www.rbccm.com/en/accessibility.page'>ACCESSIBILITY</a>
                            </li>
                        </ul>
                        <p className='small disclaimer'>
                            © RBC Dominion Securities Inc. 2023 rbccm.com is
                            an online information service operated by RBC Dominion Securities Inc.
                            and the other businesses and legal entities operating under the brand name RBC Capital Markets
                        </p>
                        <p className='small disclaimer'>
                            <img className='logo' src={irocLogo} alt='IROC Logo' />
                            <br />
                            <br />
                            Member - Canadian Investor Protection Fund
                        </p>
                    </div>
                    <div className='col-lg-1'>
                        <span className='divider-v'></span>
                    </div>
                    <div className='col-lg-3'>
                        <div className='footer-social'>
                            <div style={{ display: 'inline' }}>Connect With Us:</div>
                            <ul style={{ display: 'inline', 'fontSize': '20px' }} className='list-inline'>
                                <li>
                                    <a href='https://www.linkedin.com/company/rbc-capital-markets' target='_blank' rel="noreferrer" aria-label='LinkedIn'>
                                        <FontAwesomeIcon icon={faLinkedin} />
                                    </a>
                                </li>
                                <li>
                                    <a href='https://twitter.com/RBC' target='_blank' rel="noreferrer" aria-label='Twitter'>
                                        <FontAwesomeIcon icon={faSquareXTwitter} inverse />
                                    </a>
                                </li>
                                <li>
                                    <a href='https://www.youtube.com/user/RBC' target='_blank' rel="noreferrer" aria-label='Youtube'>
                                        <FontAwesomeIcon icon={faYoutube} />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default React.memo(Footer)