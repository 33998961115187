import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit'
import { client } from '../../api/client'
import config from '../../config';

const apiUrl = ''
const apiEndpoints = config.api;

const noticeAdapter = createEntityAdapter({
  selectId: a => a.Id,
  sortComparer: (a, b) => b.UpdatedDate.localeCompare(a.UpdatedDate)
})

const initialState = noticeAdapter.getInitialState({
  status: 'idle',
  error: null,
})

export const fetchHealth = createAsyncThunk('/', async () => {
  const endpoint = apiUrl.concat(apiEndpoints.health);
  const response = await client.get(endpoint)
  return response
}
)

const healthSlice = createSlice({
  name: 'health',
  initialState,
  reducers: {
  },
  extraReducers: {
    [fetchHealth.pending]: (state, action) => {
      state.status = 'loading'
    },
    [fetchHealth.fulfilled]: (state, action) => {
      state.status = 'succeeded' 
      noticeAdapter.setAll(state, [].concat(action.payload))
    },
    [fetchHealth.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    }
  },
})

export default healthSlice.reducer
export const {
  selectAll: selectAllHealth
} = noticeAdapter.getSelectors((state) => state.health)

