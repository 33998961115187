import { configureStore } from '@reduxjs/toolkit';


import healthReducer from '../containers/health/healthSlice'
import homeReducer from '../containers/home/homeSlice'
import indexReducer from '../containers/index/indexSlice'
import indicesReducer from '../containers/indices/indicesSlice'

export const store = configureStore({
  reducer: {
    health: healthReducer,
    home: homeReducer,
    indexList: indexReducer,
    indices: indicesReducer
  },
});
