import Cookies from 'universal-cookie';
import React, { useEffect, useCallback, useState, } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Header from '../../components/indices/header/Header';
import Search from '../../components/indices/search/Search';
import Grid from '../../components/UI/grid/indexGrid';
import PropTypes from 'prop-types';
import './indices.css'
import { fetchIndices, selectAllIndices } from './indicesSlice';
import Container from 'react-bootstrap/Container'
import { Link } from 'react-router-dom';
import { HOME } from '../../constants/routes'
import CellRenderer from '../../components/indices/cellRenderer/CellRenderer'

const Indices = () => {

  const dispatch = useDispatch()
  const cookies = new Cookies();
  const indexStatus = useSelector((state) => state.indices.status)
  const indices = useSelector(selectAllIndices)

  useEffect(() => {
    if (indexStatus === 'idle' || cookies.get('find')) {
      if (cookies.get('find')) {
        if (cookies.get('find') === '*') dispatch(fetchIndices(''))
        else {
          document.getElementById("globalIndexSearch").value = cookies.get('find');
          dispatch(fetchIndices(cookies.get('find')))
        }
        cookies.remove('find', { path: '/' });
      }
      else dispatch(fetchIndices(""))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexStatus, dispatch])

  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);

  let gridapi;

  const onBtSaveOrderAndVisibilityState = () => {
    var allState = gridColumnApi.getColumnState();
    cookies.set('columnStates', allState);
  };

  const onPageSizeChanged = (newPageSize) => {

    gridapi.paginationSetPageSize(newPageSize);
    let records = getText("[ref='lbRecordCount']");
    let lastRecInPage = newPageSize * (gridapi.paginationGetCurrentPage() + 1);
    if (lastRecInPage > records) lastRecInPage = records;
    setText("[ref='lbLastRowOnPage']", lastRecInPage);
  };

  function setText(selector, text) {
    document.querySelector(selector).innerHTML = text;
  }
  function getText(selector) {
    return document.querySelector(selector).textContent;;
  }

  const onPaginationChanged = () => {
    if (gridApi) {
      let records = getText("[ref='lbRecordCount']");
      let lastRecInPage = gridApi.paginationGetPageSize() * (gridApi.paginationGetCurrentPage() + 1);
      if (lastRecInPage > records) lastRecInPage = records;
      setText("[ref='lbLastRowOnPage']", lastRecInPage);
    }
  }

  const onGridReady = useCallback(params => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    gridapi = params.api;
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    params.api.sizeColumnsToFit()
    if (!cookies.get('columnStates')) {
      params.columnApi.resetColumnState();
    } else {
      params.columnApi.setColumnState(cookies.get('columnStates'));
      params.api.sizeColumnsToFit();
    }
    const el = `<span class="ag-paging-row-summary-panel"><label>Rows per page </label>
    <select id="gridpageselection"  aria-label="page size selection" className="option" tabIndex="0">
    <option value='10'>10</option>
    <option value='20' selected="true">20</option>
    <option value='50'>50</option>
    <option value='100'>100</option>
    </select></span>`;

    var htmlObject = document.createElement('div');
    htmlObject.className = "myDiv";
    htmlObject.innerHTML = el;
    let pagination = document.getElementsByClassName("ag-paging-page-summary-panel");
    pagination[0].insertAdjacentElement('beforeend', htmlObject);
    document.querySelector("#gridpageselection").addEventListener('change', (e) => { onPageSizeChanged(e.target.value) });

  }, []);

  const SearchButtonHandler = (arg) => {
    dispatch(fetchIndices(arg));
  }

  return (
    <div role="main" >
      <Header />
      <Search find={SearchButtonHandler} />
      <Container id="gridcnt" style={{ paddingLeft: '0px' }}>
        <br></br>
        <div className='nav nav-pills'>
          <div className='nav-item '>
            <div className='px-2 '>
              <Link className='nav-link px-0  link-dark' style={{ display: 'initial', 'paddingLeft': '0px' }} aria-current='page' to={HOME}>Home</Link>
            </div>
          </div>
          |
          <div className='nav-item '>
            <div className='px-2 roadmap' style={{ fontWeight: '500' }}>Current Indices
            </div>
          </div>
        </div>
        <Grid tabIndex="0"
          {...{
            columnDefs: [
              {
                field: 'Name',
                headerName: 'Index Name',
                headerTooltip: 'Index Name',
                filter: true,
                width: 200,
                minWidth: 150,
                maxWidth: 200,
                wrapText: true,
                cellRendererFramework: CellRenderer,
                lockPinned: true,
                pinned: 'left'
              },
              {
                field: 'Ticker',
                headerName: 'Ticker',
                headerTooltip: 'Ticker',
                width: 100,
                minWidth: 100,
                wrapText: true
              },
              {
                field: 'AssetClass.Name',
                headerName: 'Asset Class',
                headerTooltip: 'Asset Class',
                width: 120,
                minWidth: 120
              },
              {
                field: 'Level',
                headerName: 'Level',
                headerTooltip: 'Level',
                width: 80,
                minWidth: 80
              },
              {
                field: 'Currency.Name',
                headerName: 'Currency',
                headerTooltip: 'Currency',
                width: 100,
                minWidth: 100
              },
              {
                field: 'Region.Name',
                headerName: 'Region',
                headerTooltip: 'Region',
                width: 80,
                minWidth: 80
              },

              {
                field: 'Style.Name',
                headerName: 'Style',
                headerTooltip: 'Style',
                width: 110,
                minWidth: 80
              },
              {
                field: 'ReturnType.Name',
                headerName: 'Return Type',
                headerTooltip: 'Return Type',
                width: 130,
                minWidth: 90
              },
              {
                field: 'Sector.Name',
                headerName: 'Sector',
                headerTooltip: 'Sector',
                width: 80,
                minWidth: 80
              },
              {
                field: 'Strategy.Name',
                headerName: 'Strategy',
                headerTooltip: 'Strategy',
                width: 110,
                minWidth: 90
              },
              {
                field: 'Direction.Name',
                headerName: 'Direction',
                headerTooltip: 'Direction',
                width: 110,
                minWidth: 100,
                wrapText: true
              },
              // Hiding the CorrelationToEquities, CorrelationToCommodities and CorrelationToBonds fields 
              // {
              //   field: 'CorrelationToEquities',
              //   headerName: 'Correlation to Equities',
              //   headerTooltip: 'Correlation To Equities',
              //   width: 200,
              //   minWidth: 110
              // },
              // {
              //   field: 'CorrelationToCommodities',
              //   headerName: 'Correlation to Commodities',
              //   headerTooltip: 'Correlation to Commoditiess',
              //   width: 200,
              //   minWidth: 130
              // },
              // {
              //   field: 'CorrelationToBonds',
              //   headerName: 'Correlation to Bonds',
              //   headerTooltip: 'Correlation to Bonds',
              //   width: 200,
              //   minWidth: 110
              // }
            ],
            defaultColDef: {
              cellStyle: {
                'display': 'flex',
                'justify-content': 'left',
                'align-items': 'center',
                'text-align': 'left',
                'word-break': 'normal'
              },
              filter: true,
              flex: 1,
              sortable: true,
              resizable: true,
              wrapText: true,
              headerClass: 'headerCol',
              headerComponentParams: {
                template:
                  '<div class="ag-cell-label-container" role="grid">' +
                  '  <span ref="eMenu" aria-hidden="true" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                  '  <div ref="eLabel" class="ag-header-cell-label" role="row">' +
                  '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                  '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                  '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                  '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                  '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                  '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                  '  </div>' +
                  '</div>',
              },
            },
            sideBar: {
              toolPanels: ['columns'],
              headerHeight: 35
            },
            ensureDomOrder: true,
            pivotPanelShow: false,
            enablePivot: false,
            enableRowGroup: false,
            onGridReady,
            onColumnMoved: () => {
              onBtSaveOrderAndVisibilityState();
              return;
            },
            onColumnVisible: () => {
              onBtSaveOrderAndVisibilityState();
              return;
            },
            pagination: true,
            paginationPageSize: 20,
            rowHeight: 100,
            rowClass: 'rowGrid',
            getRowClass: params => {
              if (params.node.rowIndex % 2 === 0) {
                return 'white-bg';
              }
            },
            headerHeight: 70,
            domLayout: 'autoHeight',
            rowData: indices,
            paginationNumberFormatter: function (params) {
              return params.value.toLocaleString();
            },
            suppressFieldDotNotation: true,
            suppressContextMenu: true,
            onPaginationChanged: () => {
              onPaginationChanged();
              return;
            },
          }}
        />
      </Container>
    </div>)

}

Indices.propTypes = {
  classes: PropTypes.shape({
    grid: PropTypes.string.isRequired,
    root: PropTypes.string.isRequired,
  })
}

export default Indices;
