import Grid from '@TB80/aura-grid/grid'
import React from 'react'
import { gridLicense } from '../../../constants/app'


const IndexGrid = props => (
    <Grid {...{
        ...props,
        licenseKey: gridLicense
    }} />
)

IndexGrid.displayName = 'AUGrid'


export default IndexGrid
