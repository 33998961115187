import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'
import TagManager from 'react-gtm-module'
import { googleTagManagerId } from './config/config.json'
import Footer from './components/footer/Footer'
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './components/header/Header'
import Home from './containers/home/Home'
import Indices from './containers/indices/Indices'
import { HEALTH_CHECK, HOME, INDEX, INDICES, INDEX_DOC, LEGAL } from './constants/routes'
import HealthCheck from './containers/health/health'
import Index from './containers/index/Index'
import Legal from './containers/legal/legal';


const App = () => {

  if (googleTagManagerId) {
    TagManager.initialize({
        gtmId: googleTagManagerId
    })
  }

  return (
    <Router>
      <Header />
      <Content />
      <Footer />
    </Router>
  )
}

const Content = React.memo(() => (
  <Switch>
    <Route exact path={HOME}><Home /></Route>
    <Route exact path={INDICES} ><Indices /></Route>
    <Route exact path={INDEX}><Index /></Route>
    <Route exact path={INDEX_DOC}><Index /></Route>
    <Route exact path={LEGAL}><Legal/></Route>
    <Route exact path={HEALTH_CHECK}><HealthCheck/></Route>
    <Redirect to="/" />
  </Switch>
))


export default React.memo(App)
