import CircularProgress from '@TB80/aura-components/circular-progress'
import { withStyles, withTheme } from '@TB80/aura-components'
import Typography from '@TB80/aura-components/typography'
import classnames from 'classnames'
import getOr from 'lodash/fp/getOr'
import isEmpty from 'lodash/fp/isEmpty'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles'


const Sizes = {
    LARGE: 'large',
    MEDIUM: 'medium',
    SMALL: 'small'
}

const sizeToValue = size => getOr(3, size, {

    [Sizes.SMALL]: 2,
    [Sizes.MEDIUM]: 3,
    [Sizes.LARGE]: 4

})

const Loader = ({

    classes,
    className = '',
    label = '',
    size = Sizes.MEDIUM,
    theme

}) => (<div className={classnames(className, classes.root)}>
    <CircularProgress
        size={theme.spacing(sizeToValue(size))}
        thickness={6}
    />
    {!isEmpty(label) && (
        <Typography className={classes.label} variant="h3">
            {label}
        </Typography>
    )}
</div>)

Loader.displayName = 'Loader'

Loader.propTypes = {
    classes: PropTypes.shape({
        label: PropTypes.string.isRequired,
        root: PropTypes.string.isRequired
    }).isRequired,
    className: PropTypes.string,
    label: PropTypes.string,
    size: PropTypes.string,
    theme: PropTypes.shape({
        spacing: PropTypes.func.isRequired
    }).isRequired
}

Loader.Sizes = Sizes

export default withTheme((withStyles(styles)(Loader)))
